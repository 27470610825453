import { createState, useHookstate } from '@hookstate/core';

const globalState = createState({
  country: '',
  gdpr: null,
  dirty: false,
});

export function useGlobalState() {
  return useHookstate(globalState);
}

export function setGlobalState(country, gdpr) {
  globalState.set({
    country,
    gdpr,
    dirty: true,
  });
}
