import 'whatwg-fetch';

import wrapPage from './src/intl-wrap-page';
import { setGlobalState } from './src/utils/globalState';

const SITE_COUNTRY = process.env.GATSBY_SITE_COUNTRY;

export const wrapRootElement = wrapPage;

export const onInitialClientRender = () => {
  import('intersection-observer');
};

export const onClientEntry = async () => {
  try {
    // const response = await fetch(`https://geoip.richardsonshop.com/`);
    const response = await fetch(
      `https://us-east1-resolute-spirit-303614.cloudfunctions.net/geoip-v2`,
    );
    const { shop, gdpr } = await response.json();
    setGlobalState(shop, gdpr);
  } catch (error) {
    throw new Error(error);
  }
};
