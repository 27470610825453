import React from 'react';
import { IntlProvider } from 'react-intl';
import './lib/shopify-helper';

import enJSON from 'intl/en.json';
import jaJSON from 'intl/ja.json';
import { ContextProvider } from './lib/shopify-helper';

const messageMap = {
  en: enJSON,
  ja: jaJSON,
};

function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

function getMessages(language) {
  const messages = messageMap[language];
  return flattenMessages(messages);
}

const config = {
  path: `./src/intl`,
  language: process.env.GATSBY_SITE_LANGUAGE,
};

const messages = getMessages(config.language);

export default ({ element }) => {
  const { language } = config;

  return (
    <ContextProvider shopName={process.env.GATSBY_SHOPIFY_STORE_URL} accessToken={process.env.GATSBY_SHOPIFY_ACCESS_TOKEN}>
      <IntlProvider locale={language} messages={messages}>
        {element}
      </IntlProvider>
    </ContextProvider>
  );
};
